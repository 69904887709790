/* EXOSKELETON BOOTSTRAP 4 */

// TYPO Roboto, Roboto Condensed, Assistant, Meie+Script

  $baseSans:'Roboto';
  $baseCondensed:'Roboto Condensed';
  $baseBold:'Roboto';
  $baseDecor: 'Baloo';
  $baseBlock: 'Roboto';
  $baseMonotype: 'Courier';
  $baseSerif: 'Georgia';
  $baseThin: 'Roboto Light' ;

  $menuTypo: $baseSans;
  $baseFont: $baseSans;
  $sans-serif: sans-serif;
  $headerFont: $baseBlock;
  $formFont: $baseSans;

  $defaultLetterSpacing: 0.05em;
  $defaultHeaderLineHeight: 1.2em;
  $baseFontSize: 1em;

  // This site
  $bgPostOpaq: 0.6;
  $rgbOpaqBrown:rgba(140, 123, 104, $bgPostOpaq);
  $rgbOpaqPink:rgba(173, 117, 147, $bgPostOpaq);
  $rgbOpaqOrange:rgba(255, 147, 30, $bgPostOpaq);
  $rgbOpaqGreen:rgba(141, 162, 134, $bgPostOpaq);
  $rgbOpaqBlue:rgba(127, 127, 139, $bgPostOpaq);
  $rgbOpaqCoolBlue:rgba(182, 204, 212, $bgPostOpaq);
  $rgbOpaqDarkBrown:rgba(140, 123, 104,0.6);

  // EXOSKELETON
  $exo-blue: #0081D5;
  $exo-light-blue: #6DC6FE;
  $exo-green: #478C88;
  $exo-light-green: #03BD5B;
  $exo-orange: #FF9947;
  $exo-dark-orange: #EA6703;
  $exo-red: #D1335B;
  $exo-light-red: #F74E7B;
  $exo-pink: #FC76D9;
  $exo-violet: #A939B9;
  $exo-light-violet: #E0A5EA;
  $exo-grey-7:#374355;
  $exo-grey-6:#3D4D65;
  $exo-grey-5:#53657D;
  $exo-grey-4:#8393A7;
  $exo-grey-3:#ADB9C9;
  $exo-grey-2:#C9D3DF;
  $exo-grey-1:#EBEFF3;
  $exo-grey-green:#A7C3B4;
  $exo-grey-blue: #819EBD; 

  $bootstrap-danger: #ebccd1;
  $bootstrap-warning: #faebcc;
  $bootstrap-success: #d6e9c6;
  $bootstrap-info: #bce8f1;
  $bootstrap-default: #bce8f1;

  $defaultRadius:15px;
  $smallRadius:5px;
  $circleRadius:50%;
  $contentDivMargin: 15px 0px 5px 0px;
  $pixelBorder: 1px solid black;

  $baseDropShadow: 3px 3px 3px 0px #888;
  $navbar-inverse-bg: #000;
  $navbar-inverse-border: #333;

/* #MENU */
$menu-top-bkg: $rgbOpaqPink;
$menu-middle-bkg: $rgbOpaqBlue;
$menu-bottom-bkg: $rgbOpaqOrange;
$collapse-nav-border: $rgbOpaqDarkBrown;
$collapse-nav-hover-bkg: $rgbOpaqOrange;

/* #COLORS */
.green{ color:$exo-green !important; }
.light-green{ color:$exo-light-green !important; }
.red{ color:$exo-red !important; }
.light-red{ color: $exo-light-red !important; }
.blue{ color:$exo-blue !important; }
.light-blue{ color: $exo-light-blue !important; }
.orange { color:$exo-orange !important; }
.darkorange { color:$exo-dark-orange !important; }
.darkgrey{ color: $exo-grey-6 !important; }
.lightgrey{ color: $exo-grey-3 !important; }
.grey{ color: $exo-grey-4 !important; }
.violet{ color: $exo-violet !important; }
.greygreen{ color: $exo-grey-green !important; }
.greyblue{ color: $exo-grey-blue !important; }
.pink{ color: $exo-pink !important; }


/* #BACKGROUNDS */
.green-bkg{ background-color:$exo-green !important; }
.light-green-bkg{ background-color:$exo-light-green !important; }
.red-bkg{ background-color:$exo-red !important; }
.light-red-bkg {background-color: $exo-light-red;}
.blue-bkg{ background-color:$exo-blue !important; }
.light-blue-bkg{ background-color:$exo-light-blue !important; }
.orange-bkg{ background-color:$exo-orange !important; }
.darkgrey-bkg{ background-color: $exo-grey-6 !important; }
.lightgrey-bkg{ background-color: $exo-grey-3 !important; }
.violet-bkg{ background-color: $exo-light-violet !important; }
.greygreen-bkg{ background-color: $exo-grey-green !important; }
.greyblue-bkg{ background-color: $exo-grey-blue !important; }



@mixin DropShadow($shadow:$baseDropShadow){
  -moz-box-shadow:    $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow:         $shadow;
}

 
@mixin RoundedShape($radius:$defaultRadius){
    -webkit-border-radius:$radius;
       -moz-border-radius:$radius;
            border-radius:$radius; 
}


@mixin RoundShape($radius:$circleRadius){
    -webkit-border-radius:$radius;
       -moz-border-radius:$radius;
            border-radius:$radius; 
}

@mixin RoundedMinShape($radius:$smallRadius){
    -webkit-border-radius:$radius;
       -moz-border-radius:$radius;
            border-radius:$radius; 
}

@mixin DefaultOpacity(){
    opacity: 0.8;
    filter: alpha(opacity=80); /* For IE8 and earlier */
}


  .glyphicons{
  position:relative;
  top:3px;
  padding-right: 2px;
  display:inline-block;
  font-family:'Glyphicons Regular', "Roboto" ,Arial , sans-serif ;
  font-style:normal;font-weight:normal;
  line-height:1;vertical-align:top;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

/* 
    Site Specifics "Vi i Vården 
*/

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) { 
.stage .forms .anlita img, .stage .forms .jobba img{
    margin: 0 10px !important;
  }
 .post{
    h2{
      .fatten{
        font-size: calc(30px + 6 * ((100vw - 320px) / 680)) !important;
      }
    }
  }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .post{
    img{
      max-width: 50%;
    }
  }
}
 


body{
  //font-family: $baseSans;
}

header{
  .logo{
    margin: 0 auto !important; 
    width: 50%;
    display: block;
  }
  
}
h1{
    // font-family: 'Roboto';
     font-family: $baseSans;
     font-weight: 300 !important;
     color: #fff;
     font-size: 3.5em !important;
     //color:red;
    // font-weight: 900;
    // font-family: $baseBold;
  } 
.info-flik{
    //background-color: #70AAE8;
    //background-color: rgba(255, 255, 255, 0.35);
    border: 2px #70AAE8 solid;
    padding: 5px 10px;
    font-weight: 900;
    margin-top: 5px;
    float: right;
    @include RoundedMinShape;
    a{
      color: #70AAE8;;
    }
  }
.stage{
  padding-top: 30px; 
  background-image: url(/assets/images/viv-cloud-bkg.png);
  background-size: 100%;
  background-repeat: no-repeat; 
  .urub{
    font-family: $baseSans;
    color: #1C66B4;
    font-size: 1.3em !important;
    font-weight: 900 !important;
    letter-spacing: $defaultLetterSpacing;

  }
  .forms{
    .btn{
      @include RoundedShape;
      @include DropShadow;
      border: 4px solid white;
      margin: 20px 0;

    }
    .jobba{
      background-color: #BFD9C6;
      color: #36634A;
      img{
        width: 1.5em;
        margin: 0 20px 0 10px;
      }
    }
    .miljo{
      background-color: #BFD9C6;
      color: #36634A;
      img{
        width: 1.5em;
        margin: 0 20px 0 10px;
      }
    }
    .anlita{
      background-color: #5E40BF;
      img{
        width: 1.5em;
        margin: 0 10px 0 20px;
      }
    }
   a{
    font-family: $baseDecor;
    font-size: 2em;
  }

  }
  .post{
    img{
      margin: 0px 10px 30px 10px; }

    h2{
      margin-top: 4%;
      span{ 
        max-height:80%;
      }
      font-family:  $baseSans;
      font-weight: 300 !important;
      .fatten{
        font-size: 1.5em;
        white-space: nowrap;
        color: #555;
      }
    } 
  }
  background-color: #70AAE8 ;
  .card{
    background-color: rgba(255, 255, 255, 0.35);
  }
}

.fatten{
  font-weight: 900;
}

.centered-text{
  text-align: center;
}

